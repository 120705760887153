import { Box, BoxProps, Center, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React, { FC } from "react";
import CLink from "../../lib/CLink";
import GLink from "../../lib/GLink";
import Logo from "../Logo";

interface LayoutFooterProps extends BoxProps {
  appVersion: string | undefined;
}

const Footer: FC<LayoutFooterProps> = ({appVersion, ...rest}) => {
  return (
  <Box as="footer"
    bg={useColorModeValue('gray.50', 'gray.900')}
    color={useColorModeValue('gray.700', 'gray.200')}
    {...rest}>
      
    <Box py={6}>
      <Flex
        align={'center'}
        _before={{
          content: '""',
          borderBottom: '1px solid',
          borderColor: useColorModeValue('gray.200', 'gray.700'),
          flexGrow: 1,
          mr: 8,
        }}
        _after={{
          content: '""',
          borderBottom: '1px solid',
          borderColor: useColorModeValue('gray.200', 'gray.700'),
          flexGrow: 1,
          ml: 8,
        }}>
        <Logo/>
      </Flex>
      <Flex mt="3" justify="center" direction="column" alignItems="center">
        <p>
          Desarrollado en <a href="https://www.gatsbyjs.com">Gatsby</a> por <CLink href="https://github.com/jcespinoza">Juan Carlos Espinoza</CLink>
        </p>
        <p>
          <CLink color="teal.500" to={"/privacy"} as={GLink}>Política de Privacidad</CLink> - <CLink as={GLink} color="teal.500" to="/tos">Términos de Uso</CLink>
        </p>
        <Center>
          {appVersion && <p>Application version: {appVersion}</p>}
        </Center>
      </Flex>
      <Text pt={6} fontSize={'sm'} textAlign={'center'}>Residencial La Hacienda © {new Date().getFullYear()}.</Text>
    </Box>
  </Box>)
};

export default Footer;