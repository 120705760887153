export const routeList = [
  {
    heading: "Home",
    path: '/',
    title: 'Home',
    routes: [
      {
        heading: "Home",
        path: '/',
        title: 'Home',
      },
    ]
  },
];
