import { IconButton, IconButtonProps, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { AiOutlineMenu } from 'react-icons/ai';

const MobileNavButton = React.forwardRef(
  (props: IconButtonProps, ref: React.Ref<any>) => {
    return (
      <IconButton
        ref={ref}
        display={{ base: 'flex', md: 'none' }}
        fontSize='20px'
        color={useColorModeValue('gray.800', 'inherit')}
        variant='ghost'
        icon={<AiOutlineMenu />}
        {...{"aria-label": "Open Menu"}}
        {...props}
      />
    );
  }
);

export default MobileNavButton;