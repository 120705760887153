import { Image, ImageProps, useColorMode } from "@chakra-ui/react";
import React from "react";
import { default as LogoIconSvgDark, default as LogoIconSvgLight } from '../assets/images/la-hacienda-icon.svg';
import LogoLight from "../assets/images/LaHaciendaLogo.svg";
import LogoDark from "../assets/images/LaHaciendaLogo_dark.svg";

export const Logo = (props: ImageProps) => {
  const { colorMode } = useColorMode()

  return colorMode === "light" ? (
      <Image height="48px" src={LogoLight} {...props}/>
    ) : (      
      <Image height="48px" src={LogoDark} {...props}/>
  )
}

export const LogoIcon = () => {
  const { colorMode }= useColorMode()

  return colorMode === "light" ? (
      <Image height="48px" src={LogoIconSvgLight}/>
    ) : (      
      <Image height="48px" src={LogoIconSvgDark}/>
  )
}

export default Logo