import { Box, chakra, Flex, HTMLChakraProps, IconButton, Link, Spacer, useColorMode, useColorModeValue, useDisclosure, useUpdateEffect } from "@chakra-ui/react";
import { useNavigate } from "@reach/router";
import { useViewportScroll } from "framer-motion";
import React from "react";
import { FaMoon, FaSun } from 'react-icons/fa';
import Logo, { LogoIcon } from "../Logo";
import MobileNavButton from "./MobileNavButton";
import MobileNavContent from "./MobileNavContent";

const HeaderContent = () => {
  const navigate = useNavigate();
  const mobileNav = useDisclosure();
  const { toggleColorMode: toggleMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const mobileNavBtnRef = React.useRef<HTMLButtonElement>();

  useUpdateEffect(() => {
    mobileNavBtnRef.current?.focus();
  }, [mobileNav.isOpen]);

  return (
    <>
      <Flex w='100%' h='100%' px='6' align='center' justify='space-between'>
        <Flex align='center'>
          <Link display='block'
           aria-label='Back to homepage' 
            onClick={() => {
              console.log('clicked', navigate);
              
              navigate?.('/')

            }} 
          >
            <Logo display={{ base: 'none', md: 'block' }}/>
            <Box minW='3rem' display={{ base: 'block', md: 'none' }}>
              <LogoIcon />
            </Box>
          </Link>
        </Flex>
        <Spacer/>
        <Flex
          justify='flex-end'
          w='100%'
          align='center'
          color='gray.400'
          maxW='1100px'
        >
          <IconButton
            size='md'
            fontSize='lg'
            aria-label={`Switch to ${text} mode`}
            title={`Switch to ${text} mode`}
            variant='ghost'
            color='current'
            ml={{ base: '0', md: '3' }}
            onClick={toggleMode}
            icon={<SwitchIcon />}
          />
          
          <MobileNavButton
            ref={mobileNavBtnRef}
            aria-label='Open Menu'
            onClick={mobileNav.onOpen}
          />
        </Flex>
      </Flex>
      <MobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose} />
    </>
  );
}

const Header = (props: HTMLChakraProps<'header'>) => {
  const bg = useColorModeValue('white', 'gray.800');
  const ref = React.useRef<HTMLHeadingElement>();
  const [y, setY] = React.useState(0);
  const { height = 0 } = ref.current?.getBoundingClientRect() ?? {};

  const { scrollY } = useViewportScroll();
  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);

  return (
    <chakra.header
      ref={ref as any}
      shadow={y > height ? 'sm' : undefined}
      transition='box-shadow 0.2s, background-color 0.2s'
      pos='sticky'
      top='0'
      zIndex='3'
      bg={bg}
      left='0'
      right='0'
      width='full'
      borderBottomWidth='1px'
      {...props}
    >
      <chakra.div height='4rem' mx='auto' maxW='8xl'>
        <HeaderContent />
      </chakra.div>
    </chakra.header>
  );
}

export default Header;