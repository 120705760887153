import { Box, useColorModeValue } from "@chakra-ui/react"
import { SkipNavLink } from "@chakra-ui/skip-nav"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { appRootSelector, thunkSetAppVersion } from "../../state/app"
import Seo from "../Seo"
import Footer from "./Footer"
import Header from "./Header"


type LayoutProps = { }

const PageLayout: React.FC<LayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { version:  appVersion } = useSelector(appRootSelector);

  useEffect(() => {    
    dispatch(thunkSetAppVersion());
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
    `)

  return (
    <>
      <Seo title={data.title}/>
      <SkipNavLink zIndex={20}>Skip to Content</SkipNavLink>
      <Box minH="calc(100vh - 14.5rem)" bg={useColorModeValue("gray.100", "gray.900")}>
        <Header/>
        <Box as="main" textAlign='center'>
          {children}
        </Box>
      </Box>
      <Footer appVersion={appVersion}/>
    </>
  )
}

export default PageLayout