import { Box, BoxProps, chakra, useColorModeValue } from "@chakra-ui/react"
import { useLocation } from "@reach/router"
import React, { FC } from "react"
import RouteItem from "../../models/RouteItem"
import SidebarLink from "./SideBarLink"

interface SideBarProps extends BoxProps {
  routes: RouteItem[]
}
const Sidebar: FC<SideBarProps> = ({ routes, ...rest }) => {
  const { pathname } = useLocation()
  const ref = React.useRef<HTMLDivElement>(null)

  return (
    <Box
      bg={useColorModeValue("green.500", "teal.900")}
      ref={ref}
      as="nav"
      aria-label="Main Navigation"
      pos="sticky"
      sx={{
        overscrollBehavior: "contain",
      }}
      //top="6.5rem"
      w="280px"
      h="calc(100vh)"
      pr="8"
      pb="6"
      pl="6"
      //pt="4"
      overflowY="auto"
      className="sidebar-content"
      flexShrink={0}
      display={{ base: "none", md: "block" }}
      {...rest}
    >
      <SidebarContent routes={routes} pathname={pathname} contentRef={ref} />
    </Box>
  )
}

export type SidebarContentProps = {
  pathname?: string
  contentRef?: any
  routes: RouteItem[]
}

export function SidebarContent(props: SidebarContentProps) {
  const { routes, pathname, contentRef } = props
  const color = useColorModeValue("gray.700", "inherit")
  return (
    <>
      {routes.map((lvl1: RouteItem, idx) => {
        return (
          <React.Fragment key={idx}>
            {lvl1.heading && (
              <chakra.h4
                fontSize="sm"
                fontWeight="bold"
                my="1.25rem"
                textTransform="uppercase"
                letterSpacing="wider"
                color={color}
              >
                {lvl1.title}
              </chakra.h4>
            )}
            {lvl1.routes && lvl1.routes.map((lvl2, index) => {
              if (!lvl2.routes) {
                return (
                  <SidebarLink ml="-3" mt="2" key={lvl2.path} href={lvl2.path}>
                    {lvl2.title}
                  </SidebarLink>
                )
              }
            })}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Sidebar