import { Box, BoxProps, Center, CloseButton, Flex, HStack, useBreakpointValue, useColorModeValue, useUpdateEffect } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { AnimatePresence, motion, useElementScroll } from "framer-motion";
import React, { FC } from "react";
import { RemoveScroll } from "react-remove-scroll";
import GLink from "../../lib/GLink";
import Logo from "../Logo";
import { routeList } from "../../data/routeList";
import { SidebarContent } from "./Sidebar";

interface MobileNavContentProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export function MobileNavContent(props: MobileNavContentProps) {
  const { isOpen, onClose } = props;
  const closeBtnRef = React.useRef<HTMLButtonElement>();
  const { pathname } = useLocation()
  const bgColor = useColorModeValue('white', 'gray.800');

  // useRouteChanged(onClose);

  /**
   * Scenario: Menu is open on mobile, and user resizes to desktop/tablet viewport.
   * Result: We'll close the menu
   */
  const showOnBreakpoint = useBreakpointValue({ base: true, lg: false });

  React.useEffect(() => {
    if (showOnBreakpoint == false) {
      onClose && onClose();
    }
  }, [showOnBreakpoint, onClose]);

  useUpdateEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => {
        closeBtnRef.current?.focus();
      });
    }
  }, [isOpen]);

  const [shadow, setShadow] = React.useState<string>();

  return (
    <AnimatePresence>
      {isOpen && (
        <RemoveScroll forwardProps>
          <motion.div
            transition={{ duration: 0.08 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Flex
              direction='column'
              w='100%'
              bg={bgColor}
              h='100vh'
              overflow='auto'
              pos='absolute'
              top='0'
              left='0'
              zIndex={20}
              pb='8'
            >
              <Box>
                <Flex justify='space-between' px='6' pt='5' pb='4'>
                  <Logo sx={{ rect: { fill: 'teal.300' } }} />
                  <HStack spacing='5'>
                    <CloseButton ref={closeBtnRef as any} onClick={onClose} />
                  </HStack>
                </Flex>
                <Box px='6' pb='6' pt='2' shadow={shadow}>
                </Box>
              </Box>

              <ScrollView
                onScroll={(scrolled: any) => {
                  setShadow(scrolled ? 'md' : undefined);
                }}
              >
                <SidebarContent
                  pathname={pathname}
                  routes={routeList}
                />
              </ScrollView>
            </Flex>
          </motion.div>
        </RemoveScroll>
      )}
    </AnimatePresence>
  );
}

const ScrollView = (props: BoxProps & { onScroll?: any }) => {
  const { onScroll, ...rest } = props;
  const [y, setY] = React.useState(0);
  const elRef = React.useRef<any>();
  const { scrollY } = useElementScroll(elRef);
  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);

  useUpdateEffect(() => {
    onScroll?.(y > 5 ? true : false);
  }, [y]);

  return (
    <Box
      ref={elRef}
      flex='1'
      id='routes'
      overflow='auto'
      px='6'
      pb='6'
      {...rest}
    />
  );
};

type NavLinkProps = {
  href: string;
}
const NavLink: FC<NavLinkProps> = ({ href, children }) => {
  const { pathname } = useLocation();
  const bgActiveHoverColor = useColorModeValue('gray.100', 'whiteAlpha.100');

  const [, group] = href.split('/');
  const isActive = pathname.includes(group);

  return (
    <GLink to={href}>
      <Center
        flex='1'
        minH='40px'
        as='button'
        rounded='md'
        transition='0.2s all'
        fontWeight={isActive ? 'semibold' : 'medium'}
        bg={isActive ? 'teal.400' : undefined}
        borderWidth={isActive ? undefined : '1px'}
        color={isActive ? 'white' : undefined}
        _hover={{
          bg: isActive ? 'teal.500' : bgActiveHoverColor,
        }}
      >
        {children}
      </Center>
    </GLink>
  );
}

export default MobileNavContent;
